






import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutUser: () => import("@/layouts/presets/user.vue"),
  },
})
export default class PageUser extends Vue {}
